import React from 'react'
import { RootElement } from './src/components/atoms/RootElement/RootElement'

export const onRouteUpdate = ({ location }) => {
  if (process.env.CONTEXT !== 'production') {
    return null
  }
  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
  return true
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}
