exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-digital-media-js": () => import("./../../../src/pages/digital-media.js" /* webpackChunkName: "component---src-pages-digital-media-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portofvancouver-js": () => import("./../../../src/pages/portofvancouver.js" /* webpackChunkName: "component---src-pages-portofvancouver-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-spaces-js": () => import("./../../../src/pages/spaces.js" /* webpackChunkName: "component---src-pages-spaces-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-proposal-js": () => import("./../../../src/templates/proposal.js" /* webpackChunkName: "component---src-templates-proposal-js" */)
}

